import React, { useMemo } from "react";
import styles from "./program-for-me.module.scss";
import HomeSliderVideo from "components/home-slider-video";
import PropTypes from "prop-types";

const ProgramForMe = ({srcVideo, title}) => {
  return (
    <div className={styles.container}>
      <h3 className={styles.title}>{title}</h3>
      <div className={styles.video__wrapper}>
        <HomeSliderVideo src={srcVideo} tooltip={title} />
      </div>
    </div>
  );
};

export default ProgramForMe;

ProgramForMe.propTypes = {
  srcVideo: PropTypes.string,
  title: PropTypes.string,
};