import React, { useMemo } from "react";
import PropTypes from "prop-types";
import Image from "next/image";
import Button from "react-bootstrap/Button";
import { useRouter } from "next/router";

import styles from "./choose-program.module.scss";
import { useCallback } from "react";
function Program({ week, mode }) {
  const router = useRouter();
  const isMembershipMode = useMemo(() => mode !== CHOOSE_PROGRAM_VIEW_MODE.PROGRAM, [mode]);

  const handleCardClick = useCallback(() => {
    if (isMembershipMode) {
      router.push(`/sign-up?program=${week.membership}&cmc_ref=${week.cmcRef}`);
    } else router.push(`/#choose-program`);
  }, [isMembershipMode, router, week.cmcRef, week.membership]);

  const isBestOption = useMemo(() => isMembershipMode && week.membership === TWELVE_MONTHS_MEMBERSHIP, [isMembershipMode, week.membership]);

  return (
      <div key={week.title} className={`card col-12 col-md-5 col-lg-3 mb-3 ${isBestOption && 'best-deal-card'}`}>
        {isBestOption && (
          <>
            <div className="best-deal">
              <span>Best Deal</span>
            </div>
            {/* <Image className="best-deal-image" src="/static/images/best-deal.png" alt="best-deal" width={100} height={100} loading="lazy" /> */}
          </>
        )}
        <div className="card-top">
          <div className="thumbnail">
            <Image src={week.thumbnail} alt={week.title} width={240} height={152} loading="lazy" />
          </div>
        </div>
        {isMembershipMode && (
          <div className="card-middle">
            <h3 className={"choose-program__title text-white"}>{week.middle.title}</h3>
            <span>{week.middle.subTitle}</span>
          </div>
        )}
        <div className="card-body">
          <nav className={styles["choose-program__service-list"]}>
            <ul className="nav">
              {week.body?.list?.map((data) => {
                if (data.includes("(See Our Programs)")) {
                  const parts = data.split("(See Our Programs)");
                  return (
                    <li key={data} className="nav-item">
                     {parts[0]}
                      <a href="/about-the-diet-plan#choose-program" style={{ textDecoration: "underline" }}>
                        (See Our Programs)
                      </a>
                      {parts[1]}
                    </li>
                  );
                }
                return (
                  <li key={data} className="nav-item">
                     <div className={styles['choose-program__descriptions']} dangerouslySetInnerHTML={{ __html: data }}></div>
                  </li>
                );
              })}
            </ul>
            <p className={styles["tagline"]}>{week.middle.tagline}</p>
          </nav>
        </div>
        <Button variant="danger" onClick={handleCardClick} className="week bg-red">
          {week.button}
        </Button>
      </div>
  );
}

Program.propTypes = {
  week: PropTypes.object.isRequired,
  mode: PropTypes.string,
};

export default Program;
