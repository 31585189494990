import React, { useEffect, useState, Fragment } from 'react'
import { useAccordionButton } from 'react-bootstrap/AccordionButton'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'

import styles from './accordion.module.scss'

function CustomToggle ({ children, eventKey, handleClick }) {
  const decoratedOnClick = useAccordionButton(eventKey, (e) => {
    handleClick()
  })

  return (
    <Fragment>
      <Accordion.Button
        style={{ backgroundColor: 'transparent', display: 'block' }}
        as={Button}
        variant='link'
        key={eventKey}
        onClick={decoratedOnClick}
      >
        {children}
      </Accordion.Button>
    </Fragment>
  )
}

export default function DAccordion (props) {
  const [activeKey, setActiveKey] = useState('0')
  const [numberItem, setNumberItem] = useState(99)
  const [expand, setExpand] = useState(true)
  // eslint-disable-next-line no-unused-vars
  const [dataList, setDataList] = useState([
    {
      title: 'ARE MY DETAILS SAFE?',
      content:
        'Absolutely! Security is our top priority. Your payment will be processed using our SAFE & SECURE Secure Socket Layer (SSL) encryption, the industry standard for online transactions. Rest assured, your personal and payment information is protected every step of the way.'
    },
    {
      title: 'WHAT HAPPENS WHEN I JOIN?',
      content:
        'This is where your transformation begins!<br/>' +
        '<ul><li>You’ll choose your start date, personalize your fitness and nutrition goals, and set your food preferences.</li> <li>Our proprietary "Smart Coach" software will customize your personal plan to fit your unique needs, including meal plans and workouts tailored to your lifestyle and fitness level.</li><li>You’ll gain immediate access to our exclusive training app, loaded with resources like progress trackers, grocery shopping lists, and recipes.</li><li>Join our global community of like-minded members for support and motivation on your journey.</li></ul>'+
        'This isn’t just a program—it’s your roadmap to lasting success!'
        // 'You get to choose your start date, personalize your goals, modify your food preference settings, set your level of exercise intensity, and begin walking the journey with the support of our global community.'
    },
    {
      title: 'WHAT IS THE CANCELLATION POLICY?',
      content:
        'We stand by our commitment to deliver exceptional results and flexibility!<br/>' +
        "You can cancel anytime by contacting our team at <a href='mailto:support@thedolcediet.com'><b>support@thedolcediet.com</b></a>. Terms and conditions may apply depending on your chosen plan. Keep in mind, once you cancel, you’ll lose access to all program features, so consider if pausing may be a better option."+
        "We’re here to help make the process as smooth as possible."
    },
    {
      title: 'WHEN WILL I BE CHARGED?',
      content:
        '<ul><li><b>For upfront plans</b> (4-week, 12-week, or 12-month): Your payment will be processed at registration.</li><li><b>For monthly subscriptions</b>: Payments are charged on the same day each month from the date of registration.</li></ul>' +
        "Want to cancel? No problem! Simply contact us at <a href='mailto:support@thedolcediet.com'><b>support@thedolcediet.com</b></a> before your next billing cycle." 
    },
    {
      title: 'HOW DOES THE 100% MONEY-BACK GUARANTEE WORK?',
      content:
        'We’re so confident in the power of our programs that we offer a <b>24-hour risk-free trial.</b><br/>' +
        "If, within the first 24 hours, you decide it’s not the right fit for you, email us at <a href='mailto:support@thedolcediet.com'><b>support@thedolcediet.com</b></a> to cancel. We’ll refund your initial payment—no questions asked.<br/>"+
        'This is your opportunity to take the first step without hesitation!'
    },
    {
      title: 'WHAT MAKES THE DOLCE DIET DIFFERENT?',
      content:
        'The Dolce Diet is <b>more than a program</b>—it’s a lifestyle revolution. Designed by world-class coach Mike Dolce and his team of registered dietitians and certified trainers, it’s built on decades of success with elite athletes and everyday individuals. <br/>' +
        'Unlike generic plans, our system adapts to YOU, offering:<br/>'+
        '<ul><li>Customized meal plans and workouts tailored to your specific goals and needs.</li><li>Access to exclusive tools in our training app to track progress and stay on track.</li><li>A supportive global community to cheer you on every step of the way.</li></ul>'+
        'We don’t just teach you how to lose weight—we empower you to live the lean, vibrant life you deserve.'
    },
     {
      title: 'IS THE PROGRAM SUITABLE FOR BEGINNERS?',
      content:
        'Absolutely! Whether you’re just starting out or a seasoned fitness enthusiast, our programs are fully adaptable. You control the level of exercise intensity, food preferences, and schedule, ensuring the plan fits your life perfectly.<br/>' +
        'New to fitness? Start with low-intensity workouts and easy-to-follow meals. Looking for a challenge? Crank up the intensity and dial in your nutrition. Wherever you are on your journey, we’ve got you covered.'
    },  {
      title: 'CAN I USE THE PROGRAM WITH DIETARY RESTRICTIONS?',
      content:
        'Yes! The Dolce Diet is designed to adapt to a variety of dietary needs. Whether you’re vegan, gluten-free, nut-free, dairy-free, or have other preferences, our system will create a personalized meal plan just for you.<br/>' +
        'Need further customization? Our support team is always available to help fine-tune your plan.'
    }
  ])

  const handleAddMore = (e) => {
    e.preventDefault()

    if (numberItem === 4) {
      setNumberItem(dataList.length)
      setExpand(true)
    } else {
      setExpand(false)
      setNumberItem(4)
    }
  }

  useEffect(() => {
    if (props.dataList && props.dataList.length > 0) {
      setDataList(props.dataList)
    }
  }, [props])

  return (
    <Accordion id='accordion' className={styles.accordion} defaultActiveKey={'0'}>
      <h3 className={styles.accordion__title}>Frequently asked questions</h3>
      {dataList.slice(0, numberItem).map((item, index) => {
        return (
          <Card key={index}>
            <Card.Header>
              <CustomToggle eventKey={index.toString()} handleClick={() => {
                if (activeKey === index.toString()) {
                  setActiveKey(null)
                } else {
                  setActiveKey(index.toString())
                }
              }}>{item.title}
                {
                  activeKey === index.toString() ? <FontAwesomeIcon icon={faMinus} className={'fa float-end'} />
                    : <FontAwesomeIcon icon={faPlus} className={'fa float-end'} />
                }</CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={index.toString()}>
              <Card.Body dangerouslySetInnerHTML={{ __html: item.content }}></Card.Body>
            </Accordion.Collapse>
          </Card>
        )
      })}

      {/* <Button variant='outline-secondary' className={styles['accordion__add-more'] + ' no-border no-background btn'} onClick={handleAddMore}> */}
      {/*  { expand ? 'VIEW LESS' : 'VIEW MORE' } */}
      {/*  { expand ? <MaterialIcon icon="expand_less" /> : <MaterialIcon icon="expand_more" /> } */}
      {/* </Button> */}
    </Accordion>
  )
}

DAccordion.propTypes = {
  dataList: PropTypes.array
}
