import React, { use, useEffect, useState } from "react";
import PropTypes from "prop-types";

import useMobileDetect from "helpers/mobile-detect";
import { LazyBackgroundImage } from "components/share/withImages";

import styles from "./choose-program.module.scss";

import ProgramItem from "components/share/choose-program/program";
import ListServices from "components/share/choose-program/list-services";
import DAccordion from "components/share/accordion/index";
import { membershipList, programList } from "helpers/program";
import { useMemo } from "react";
import ProgramForMe from "components/program-for-me";

export default function Index(props) {
  const currentDevice = useMobileDetect();
  const [imageUrl, setImageUrl] = useState("/static/images/bg-choose-program.jpg");
  const bgImage = "url(" + imageUrl + ") no-repeat center top / cover #000000";

  useEffect(() => {
    if (currentDevice.isMobile() && currentDevice.width < 768) {
      setImageUrl("/static/images/bg-m-choose-program-mb.jpg");
    } else {
      setImageUrl("/static/images/bg-choose-program.jpg");
    }
    LazyBackgroundImage({ src: imageUrl, alt: "Placeholder", loaded: currentDevice.width > 0 });
  }, [currentDevice.width]);

  const renderTitle = useMemo(() => {
    return props.type === CHOOSE_PROGRAM_VIEW_MODE.PROGRAM ? "our programs" : "choose your option";
  }, [props.type]);

  const cardList = useMemo(() => {
    return props.type === CHOOSE_PROGRAM_VIEW_MODE.PROGRAM ? programList : membershipList;
  }, [props.type]);

  return (
    <section id="choose-program" className={styles["choose-program"]}>
      <div className={styles["choose-program__wrapper"] + " wrapper lazy-background"} style={{ background: bgImage }}>
        <div className={styles["choose-program__caption"] + " container text-center"}>
          <h3 className={styles["choose-program__title"] + " text-uppercase"}>{renderTitle}</h3>
          <div className={styles["choose-program__list-service"]}>{props.type !== CHOOSE_PROGRAM_VIEW_MODE.PROGRAM && <ListServices />}</div>
        </div>
        <div className={styles["choose-program__caption"] + " container text-center " + styles["program-list"]}>
          <div className={styles["choose-program__card-list"] + " row"}>
            {cardList.map((card) => {
              return <ProgramItem key={card.title} week={card} mode={props.type} />;
            })}
          </div>
        </div>
        <div className={styles["choose-program__caption"] + " container text-center"}>
          {props.type !== CHOOSE_PROGRAM_VIEW_MODE.PROGRAM && <ProgramForMe srcVideo="/static/videos/Program-TDD-V3-2024-Upgrade.mp4" title="Which option is right for me?" />}
          {props.type === CHOOSE_PROGRAM_VIEW_MODE.PROGRAM && <ProgramForMe srcVideo="/static/videos/TDD V3 sizzle.mp4" title="Here's What's Waiting For You" />}
          <DAccordion />
        </div>
      </div>
    </section>
  );
}

Index.propTypes = {
  type: PropTypes.string,
};
