const membershipFeatures = {
  [FOUR_WEEKS_MEMBERSHIP]: [
    "<p>Lose Up to 21 Pounds in 21 Days:</p> <span>Jumpstart your fitness journey with the proven 3 Weeks to Shredded plan.</span>",
    "<p>Time-Saving Workouts:</p> Designed for busy people—get maximum results in minimal time.",
    "<p>Perfect for Events or Vacations:</p> Get wedding, beach, or reunion-ready with a focused plan for short-term success.",
    "<p>Trusted by the Best:</p> This is the exact system used by world-class athletes to cut weight safely and effectively.",
    "<p>Daily Progress Tools:</p> Track your meals, workouts, and results easily with guided support.",
    "<p>Effective:</p> A 100% Success Rate for over 20 years",
  ],
  [TWELVE_MONTHS_MEMBERSHIP]: [
    "<p>Unlimited Access to ALL Programs:</p>  From 3 Weeks to Shredded to Living Lean, plus exclusive releases like ShredDad, Body Armour, Squats & Steak, and more!",
    "<p>Year-Round Transformation:</p> Whether your goal is fat loss, muscle gain, or maintenance, you’ll have every tool you need for consistent progress.",
    "<p>Exclusive VIP Coaching Q&A Club:</p> Ask Mike Dolce your personal questions every month and get real answers from a world-class coach. ($7,128 VALUE INCLUDED!)",
    "<p>Fresh Content Monthly:</p> Stay motivated with brand-new workouts, meal plans, and expert tools delivered directly to you.",
    "<p>Exclusive Member Discounts:</p> Save on supplements, gear, and other perks as part of our VIP Annual community.",
    "<p>Incredible Value:</p> Barely $1.00 a day to unlock your ultimate transformation plan."
  ],
  [TWELVE_WEEKS_MEMBERSHIP]: [
    "<p>Lose Up to 40 Pounds in 12 Weeks:</p> Experience dramatic results with expert-led meal plans and workouts.",
    "<p>Tailored Programs Just for You:</p>  Choose from Living Lean, Sculpt & Tone, or other targeted plans designed to meet YOUR goals.",
    "<p>Step-by-Step Guidance:</p> Never feel lost—follow clear daily plans for meals, workouts, and accountability.",
    "<p>Track Your Progress:</p> Use the Dolce Diet Dashboard to log results and stay motivated with measurable changes.",
    "<p>Proven Results You Can Trust:</p> This system has helped thousands of busy professionals and athletes achieve their goals.",
    "<p>Affordable:</p> Transform your body, mind, and confidence in only 12 weeks."
  ],
};

const membershipThumbnails = {
  [FOUR_WEEKS_MEMBERSHIP]: "/static/images/programs/4w-full.png",
  [TWELVE_MONTHS_MEMBERSHIP]: "/static/images/programs/12m-full.png",
  [TWELVE_WEEKS_MEMBERSHIP]: "/static/images/programs/12w-full.png",
};

export const getMembershipThumbnail = (membership) => {
  return membershipThumbnails[membership] || "";
};
export const getMembershipFeatures = (membership) => {
  return membershipFeatures[membership] || [];
};

export const isFromGohighlevel = () => {
  if (typeof window !== 'undefined') {
    // Code that uses the window object
    const url = window.location.href;
    return /(&|\?)source=gohighlevel(&|$)/.test(url);
  }
};

const membershipButtonTitle = "Buy now";
const programButtonTitle = "Start program";

export const membershipList = [
  {
    title: "4 WEEK PROGRAM",
    thumbnail: getMembershipThumbnail(FOUR_WEEKS_MEMBERSHIP),
    middle: {
      title: "4-Week Plan",
      subTitle: "Rapid Results in Just 21 Days!",
      tagline: 'Start strong, see results FAST—perfect for short-term goals!'
    },
    price: "$199",
    unit: "USD/Monthly",
    time: "Monthly",
    body: {
      list: getMembershipFeatures(FOUR_WEEKS_MEMBERSHIP),
    },
    button: 'Start Your Transformation Today!',
    membership: FOUR_WEEKS_MEMBERSHIP,
    cmcRef: "ATC3wts",
  },
  {
    title: "12 MONTH OPTION",
    thumbnail: getMembershipThumbnail(TWELVE_MONTHS_MEMBERSHIP),
    price: "$399",
    unit: "USD/Yearly",
    time: "Yearly",
    middle: {
      title: "12-Month All-Access Plan",
      subTitle: "Your Ultimate Transformation Blueprint",
      tagline: 'BEST DEAL—Save 83% and transform your body, mind, and life for the entire year!'
    },
    body: {
      list: getMembershipFeatures(TWELVE_MONTHS_MEMBERSHIP),
    },
    button: "Claim Your Spot Before It’s Gone!",
    membership: TWELVE_MONTHS_MEMBERSHIP,
    cmcRef: "ATCllb",
  },
  {
    title: "12 WEEK PROGRAM",
    thumbnail: getMembershipThumbnail(TWELVE_WEEKS_MEMBERSHIP),
    price: "$299",
    unit: "USD/Quarterly",
    time: "Quarterly",
    middle: {
      title: "12-Week Plan",
      subTitle: "Transform Your Body in Just 90 Days!",
      tagline: 'Sustainable weight loss and strength-building with proven guidance.'
    },
    body: {
      list: getMembershipFeatures(TWELVE_WEEKS_MEMBERSHIP),
    },
    button: "Start Your 12-Week Journey Now!",
    membership: TWELVE_WEEKS_MEMBERSHIP,
    cmcRef: "ATCllb",
  },
];

export const programList = [
  {
    title: "3 WEEKS TO SHREDDED",
    thumbnail: "/static/images/programs/3w2s.jpg",
    middle: {},
    body: {
      list: ["Lose Up To 21 Lbs In 21 Days", "Customizable To Your Goals", "Trusted By Top Athletes", "Fast, Healthy Weight Loss", "Available On All Membership Options"],
    },
    button: programButtonTitle,
  },
  {
    title: "3W2S + LIVING LEAN",
    thumbnail: "/static/images/programs/3w2s-ll.jpg",
    middle: {},
    body: {
      list: [
        "Lose Up To 40 Lbs In 12 Weeks",
        "Start With '3 Weeks To Shredded'",
        "Transition Into 'Living Lean'",
        "Sustainable Fat Loss And Muscle Tone",
        "Available On 12 Week Program And 12 Month Option",
      ],
    },
    button: programButtonTitle,
  },
  {
    title: "LIVING LEAN + 3W2S",
    thumbnail: "/static/images/programs/ll-3w2s.jpg",
    middle: {},
    body: {
      list: [
        "Lose Up To 40 Lbs In 12 Weeks",
        "Start With 'Living Lean'",
        "Finish With '3 Weeks To Shredded'",
        "Full Transformation And Energy Boost",
        "Available On 12 Week Program And 12 Month Option",
      ],
    },
    button: programButtonTitle,
  },
  {
    title: "SHRED-DAD",
    thumbnail: "/static/images/programs/shreddad.jpg",
    middle: {},
    body: {
      list: [
        "12-week Program For Busy Men",
        "45-minute Workouts, 3 Days Per Week",
        "Build Muscle And Trim Your Waistline",
        "Balanced Nutrition, No Rabbit Food!",
        "Available On 12 Week Program And 12 Month Option",
      ],
    },
    button: programButtonTitle,
  },
  {
    title: "SCULPT & TONE",
    thumbnail: "/static/images/programs/st.jpg",
    middle: {},
    body: {
      list: [
        "12-week Program For Women",
        "Tighten, Tone, And Build Lean Muscle",
        "Fun, Functional Full-body Workouts",
        "Burn Fat While Boosting Confidence",
        "Feel Strong And Look Incredible!",
        "Available On 12 Week Program And 12 Month Option",
      ],
    },
    button: programButtonTitle,
  },
];
